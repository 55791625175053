:root {
  /* --bg-primary: #33b8b0; */
  /* --bg-primary: #01b3b5; */
  --bg-primary: #003566;
  --box-shadow-card: 0 1px 0 0 #cfd7df;
  --text-theme: #1880be !important;
  --btn-success: #01b3b5 !important;
}

body {
  /* font-family: "Poppins", sans-serif !important; */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif !important;
}

.bg-primary {
  background-color: var(--bg-primary) !important;
  color: white;
  height: 100%;
  border: none !important;
}
.btn-success {
  background-color: var(--btn-success) !important;
}

.text-bolder {
  font-weight: bolder;
}

body {
  background-color: #edf7fd !important;
}

.box-card {
  box-shadow: var(--box-shadow-card) !important;
  border: none !important;
  cursor: pointer;
  color: #183247 !important;
}

.box-card:hover {
  color: #1880be !important;
}

.text-grey {
  opacity: 0.7;
}

.box-main-card {
  box-shadow: var(--box-shadow-card) !important;
  border: none !important;
}

.f-12 {
  font-size: 12px;
}
.f-13 {
  font-size: 13px;
}

.one-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-theme {
  color: var(--text-theme) !important;
}

.btn-common:hover {
  text-decoration: underline;
}

.text-grey {
  color: #929695;
}

.bottom-card {
  height: 200px;
}

.img-bottom {
  width: 100%;
  height: 120px;
}

.ticket-card {
  background-color: #ffdee0;
  /* padding: 15px 18px !important; */
  padding: 10px 14px !important;
  height: fit-content;
}
.ticket-card-grid {
  background-color: #ffdee0;
  height: fit-content;
}

.chat-card-ticket {
  background-color: #f0f0f0;
  padding: 5px 13px !important;
  border-radius: 100px;
  width: fit-content;
  height: fit-content;
  color: var(--bg-primary) !important;
}

.agent-reply-card {
  background-color: #f5f7f9;
}
.w-content {
  width: fit-content;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.low-priority {
  color: #a0d76a;
}

.medium-priority {
  color: #4da1ff;
}
.high-priority {
  color: #ffd012;
}

.urgent-priority {
  color: #ff5959;
}

/* bg background-color */
.bg-low-priority {
  background-color: #a0d76a;
  border-radius: 100px;
  padding: 2px 10px;
}

.bg-medium-priority {
  background-color: #4da1ff;
  border-radius: 100px;
  padding: 2px 10px;
}
.bg-high-priority {
  background-color: #ffd012;
  border-radius: 100px;
  padding: 2px 10px;
}

.bg-urgent-priority {
  background-color: #ff5959;
  border-radius: 100px;
  padding: 2px 10px;
}

/* bg background-color */

.no-box-shadow {
  box-shadow: none !important;
  border: none !important;
  border-color: transparent !important;
}

.ticket-card-data i {
  vertical-align: middle;
}

.height-100 {
  height: 100vh !important;
}

.position-sticky {
  position: sticky !important;
  top: 70px;
  /* height: auto; */
  height: 90vh;
  overflow: auto;
}

.height-90 {
  height: 90vh;
  overflow: auto;
}
.height-75 {
  height: 75vh;
  overflow: auto;
}

.bg-white header {
  background-color: #fff;
  box-shadow: none !important;
  border: none !important;
  border-color: transparent !important;
}

.project-cards .card {
  max-height: 200px;
  height: 200px;
  overflow: auto;
}

.text-link:hover {
  cursor: pointer;
  color: var(--bg-primary) !important;
}

.text-click {
  cursor: pointer;
  color: var(--bg-primary) !important;
}

.text-click:hover {
  cursor: pointer;
  color: #7dd4ce !important;
}

.f-14 {
  font-size: 14px !important;
}

.button-active {
  /* background-color: #2b9a94; */
  background-color: #003566;
  border-radius: 5px;
  color: #fff;
}

.latest-ticket {
  background-color: #e0f5f0 !important;
  color: #007958 !important;
  width: fit-content !important;
  border: 1px solid #b9e7dc !important;
  font-weight: bold;
  border-radius: 100px;
}

.bg-grey {
  background-color: #f1f1f1;
}

.fw-500 {
  font-weight: 500;
}

.user-img {
  height: 100px;
  width: 100px;
  background-color: #bdb29f;
  padding: 10px;
  border-radius: 6px;
}

.no-box {
  box-shadow: none !important;
}

.width-fit-content {
  width: fit-content !important;
}

.ml-auto {
  margin-left: auto !important;
}

.search-container input {
  height: 0 !important;
}

.search-title {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.new-ticket-btn {
  background-color: transparent !important;
}

.w-300 {
  width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.notify-card {
  background-color: #ffdee0;
  padding: 5px 10px !important;
  height: fit-content;
  border-radius: 5px;
}

.css-12i7wg6-MuiPaper-root-MuiDrawer-paper::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #fff;
}

.css-12i7wg6-MuiPaper-root-MuiDrawer-paper::-webkit-scrollbar {
  width: 6px;
  background-color: #fff;
}

.css-12i7wg6-MuiPaper-root-MuiDrawer-paper::-webkit-scrollbar-thumb {
  background-color: #929695;
}

.mr-0 {
  margin-right: 0 !important;
}

.ticket-card-page .MuiOutlinedInput-root,
.ticket-card-page fieldset,
.filter-box,
.no-border {
  border: none !important;
  border-radius: 0 !important;
}

.margin-less-25 {
  /* margin-top: -25px !important; */
  background-color: #f5f7f9 !important;
}

.ticket-container .css-zxdg2z {
  padding: 0 !important;
}

.logo-img {
  width: 70px;
  height: 70px;
  padding: 5px;
  object-fit: contain;
}

.dropdown-menu-container .dropdown-submenu {
  position: relative;
  background-color: transparent !important;
  color: #000 !important;
  border: none !important;
}

.dropdown-menu-container .dropdown-item {
  background-color: transparent !important;
  color: #000 !important;
  border: none !important;
  cursor: pointer;
}

.dropdown-menu-container .dropdown-submenu .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -6px;
  margin-left: 0 !important;
  display: none;
}

.dropdown-menu-container .dropdown-submenu:hover .dropdown-menu {
  display: block;
}

.dropdown-menu-container,
.dropdown-menu-container button {
  background-color: transparent !important;
  color: #000 !important;
  border: none !important;
}

#highlightSelected h6 {
  color: var(--bg-primary) !important;
  font-weight: bolder !important;
}

.sticky-height {
  /* min-height: fit-content; */
  max-height: 70vh;
  overflow: auto;
}

.height-100-hidden {
  height: 100vh;
  overflow: hidden;
}

.grid-setup-card {
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}

.text-multi-line {
  overflow: hidden;
  max-height: 37px;
  text-overflow: ellipsis;
  line-height: 1;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}

.height-ticket-grid {
  height: 100px;
}

.w-fit-content {
  width: fit-content !important;
}
.f-11 {
  font-size: 11px !important;
}

.custom-dropdown-toggle {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.padding-lr {
  padding-left: 1rem !important;
  padding-right: 3rem !important;
}

.resize-this-container {
  resize: horizontal;
  overflow: auto;
}

.logo-table {
  width: 45px;
  height: 45px;
  object-fit: contain;
}

.w-90 {
  width: 90%;
}

.box-file {
  border: 1px solid #000;
}

.btn-hover:hover {
  color: var(--bg-primary) !important;
  text-decoration: underline;
}

.br-6 {
  border-radius: 6px;
}

.border-ticket-msg {
  border: 1px solid #c5c5c5 !important;
  border-radius: 6px !important;
}

.border-ticket-msg p {
  margin-bottom: 0 !important;
}

.ticket-time {
  color: #777777 !important;
}

.w-110 {
  width: 110px !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.ai-gen-btn:disabled {
  color: #fff;
  opacity: 1;
  background-color: #707070 !important;
}

.clear-notify {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.f-20 {
  font-size: 25px;
}

.ticket-preview-box {
  border-radius: 6px;
  width: 100%;
}

.w-fc {
  width: fit-content !important;
}

.bg-excel i {
  color: #175935 !important;
}
.bg-pdf,
.bg-excel {
  background-color: #fff !important;
  border: none !important;
}
.bg-pdf i {
  color: #ff0808 !important;
}

.pill-shape {
  border: 2px solid #000;
  width: fit-content;
  padding: 2px 15px;
  border-radius: 100px;
  font-size: 12px;
}

.reports-table th,
.reports-table td,
.dashboard-table th,
.dashboard-table td {
  vertical-align: middle;
}
